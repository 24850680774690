<template>
  <div class="modal-mask" @click="handleBackdropClick">
    <div class="modal-wrapper" id="modalForm">
      <section :class="'modal-container card '+(size || '')">
        <header class="modal-header card-header">
          <slot name="header" :title="title">
            <h2 class="card-title">
              {{title}}
              <a href="javascript:;" class="pull-right" @click="closemodal"><i class="fas fa-times"></i></a>
            </h2>
          </slot>
        </header>

        <div class="card-body">
          <div class="validation-message" v-if="errors && errors.length">
            <ul style="display: block;">
              <li v-for="p in errors" :key="p">
                <label id="fullname-error" class="error" for="fullname" style="">{{ p }}</label>
              </li>
            </ul>
          </div>
          <slot name="body" :params="params" :clickHandler="clickHandler">
          </slot>
        </div>

        <footer class="card-footer">
          <div class="row">
            <div class="col-md-12 text-center">
              <button class="btn btn-sm btn-default pull-left" @click="closemodal">
                Fechar
              </button>
              <slot name="footer" :clickHandler="clickHandler" :params="params">

              </slot>
            </div>
          </div>
        </footer>
      </section>
    </div>
  </div>
</template>

<script>
export default {
    data() {
        return {
        }
    },
    props: ['title', 'errors', 'closemodal', 'params', 'size'],
    methods: {
        handleBackdropClick: function ($event) {
            if ($event.target.className === "modal-wrapper" ||
                $event.target.className === "modal-mask") {
                this.closemodal();
                $event.stopPropagation();
            }
        },

        clickHandler: function (emit, p, p2) {
            this.$emit(emit, p, p2);
        }
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.form-row{
    overflow:auto;
}
</style>
