<template>
  <div class="item-filtro col-lg-12 col-md-6">
    <div class="checkbox-custom checkbox-default">
      <input type="checkbox"
             class=""
             :id="id + '-check'"
             @change="$emit('ativa-filtro')"
             :checked="filtro.active" />
      <label :for="id + '-check'">{{filtro.title}}</label>
    </div>
    <div class="ml-3" :data-filtro="id" v-if="filtro.active">
      <input :type="filtro.type || 'text'"
             :name="id"
             :placeholder="filtro.placeholder"
             v-if="!filtro.source && filtro.type != 'date'"
             v-model="filtro.value"
             class="form-control form-control-sm" />
      <div v-if="!filtro.source && filtro.type == 'date'">
        <div>
          Maior que: <input :type="filtro.type || 'text'"
                            :name="id"
                            :placeholder="filtro.placeholder"
                            v-model="filtro.valuede"
                            class="form-control form-control-sm" />
        </div>
        <div>
          Menor que: <input :type="filtro.type || 'text'"
                            :name="id"
                            :placeholder="filtro.placeholder"
                            v-model="filtro.valueate"
                            class="form-control form-control-sm" />
        </div>
      </div>
      <template v-if="filtro.source">
        <checkbox-filter v-for="item in filtro.source"
                         :key="item.id+'-'+item.value"
                         :id="item.id"
                         :title="item.title"
                         :checked="item.checked"
                         :value="item.value"
                         :type="item.sourceType"
                         :item="item"
                         :filtro="filtro"
                         @ativa-filtro-checkbox="item.checked = !item.checked">
        </checkbox-filter>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: ['id', 'filtro'],
};
</script>


