<template>
  <div :class="(filtro.sourceType || 'checkbox') +'-custom '+ (filtro.sourceType || 'checkbox') +'-default'">
    <input :type="filtro.sourceType || 'checkbox'"
           class=""
           :id="id+'-'+value"
           :value="value"
           :name="id"
           v-model="filtro.value"
           @change="$emit('ativa-filtro-checkbox')" />
    <label :for="id+'-'+value">{{title}}</label>
  </div>
</template>

<script>
export default {
  props: ['id', 'title', 'checked', 'value', 'type', 'item', 'filtro'],
};
</script>
