<template>
  <div>
      <loader v-if="$root.loading"></loader>
    <div>
      <navbar v-if="$root.authenticated"></navbar>
      <div class="inner-wrapper" :style="!$root.authenticated && 'padding: 0!important'">
        <section role="main" class="content-body">
          <!-- start: page -->
          <div class="row">
            <div class="col-12">
              <transition name="slide" mode="out-in">
                <router-view></router-view>
              </transition>
            </div>
          </div>
          <!-- end: page -->
        </section>
      </div>
    </div>
  </div>
</template>
<script>
  import Header from '@/components/Header.vue';
  import Loader from '@/components/Loader.vue';
  
  export default {
    name: 'App',
    components: {
      'navbar': Header,
      'loader': Loader
    },

  }
</script>
