const baseUrl = "localhost:5001";
const baseUrlPrd = "api.estoqueauto.com.br";
const isDev = location.origin.includes('localhost');

export function getEnvironmentUrl() {
  const complete = `${isDev ? 'https://' : location.protocol}//${isDev ? baseUrl : baseUrlPrd}`;
  console.log(complete);
  return complete;
  //return location.origin.includes('localhost') ? 'https://sandbox.estoqueauto.com.br' : 'https://api.estoqueauto.com.br'
}
