<template>
  <header class="header header-nav-menu header-nav-top-line">
    <div class="logo-container">
      <a href="/veiculos" class="logo">
        <img :src="$root.user.EmpresaPathLogo || '/img/logo.png'" alt="GPV" />
      </a>
      <button class="btn header-btn-collapse-nav d-lg-none" data-toggle="collapse" data-target=".header-nav">
        <i class="fas fa-bars"></i>
      </button>

      <!-- start: header nav menu -->
      <div class="header-nav collapse">
        <div class="header-nav-main header-nav-main-effect-1 header-nav-main-sub-effect-1 header-nav-main-square">
          <nav>
            <ul class="nav nav-pills" id="mainNav">
              <li class="menu-root">
                <router-link class="nav-link" to="/">
                  Estoque
                </router-link>
              </li>
              <li class="menu-root">
                <router-link class="nav-link" to="/avaliacoes">
                  Avaliações
                </router-link>
              </li>
              <li class="menu-root dropdown">
                <a href="#" class="nav-link dropdown-toggle" @click="toggleDropdown">Relatórios</a>
                <ul class="dropdown-menu">
                  <li>
                    <router-link class="nav-link" to="/relatorios/dashboarddespesas">
                      Dashboard Requisições

                    </router-link>
                  </li>
                  <li>
                    <router-link class="nav-link" to="/relatorios/dashboardencerrados">
                      Dashboard Notas Efetivas

                    </router-link>
                  </li>
                  <li>
                    <router-link class="nav-link" to="/relatorios/requisicoes">
                      Despesas

                    </router-link>
                  </li>
                  <li>
                    <router-link class="nav-link" to="/relatorios/encerramentos">
                      Encerramentos

                    </router-link>
                  </li>
                  <li>
                    <router-link class="nav-link" to="/relatorios/prontos">
                      Veículos prontos
                    </router-link>
                  </li>
                  <li>
                    <router-link class="nav-link" to="/relatorios/despesasestoque">
                      Despesas em Estoque
                    </router-link>
                  </li>
                  <li>
                    <router-link class="nav-link" to="/relatorios/encerradosporperiodo">
                      Enc. por Período
                    </router-link>
                  </li>
                </ul>
              </li>

              <li class="menu-root dropdown">
                <a href="#" class="nav-link dropdown-toggle" @click="toggleDropdown">Cadastros</a>
                <ul class="dropdown-menu">
                  <li class="dropdown-submenu">
                    <a class="nav-link" href="../#" @click="toggleDropdown">
                      Avaliação
                      <i class="fas fa-caret-down"></i>
                    </a>
                    <ul class="dropdown-menu">
                      <li>
                        <router-link class="nav-link" to="/parametros/carrocerias">
                          Carrocerias
                        </router-link>
                      </li>
                      <li>
                        <router-link class="nav-link" to="/parametros/checklists">
                          Checklists
                        </router-link>
                      </li>
                      <li>
                        <router-link class="nav-link" to="/parametros/combustiveis">
                          Combustíveis
                        </router-link>
                      </li>
                      <li>
                        <router-link class="nav-link" to="/parametros/cores">
                          Cores
                        </router-link>
                      </li>
                      <li>
                        <router-link class="nav-link" to="/parametros/opcionais">
                          Opcionais
                        </router-link>
                      </li>
                      <li>
                        <router-link class="nav-link" to="/parametros/transmissoes">
                          Transmissões
                        </router-link>
                      </li>

                    </ul>
                  </li>
                  <li>
                    <router-link class="nav-link" to="/parametros/fornecedores">
                      Fornecedores
                    </router-link>
                  </li>
                  <li>
                    <router-link class="nav-link" to="/parametros/departamentos">
                      Departamentos
                    </router-link>
                  </li>
                  <li>
                    <router-link class="nav-link" to="/parametros/servicos">
                      Serviços padrão
                    </router-link>
                  </li>
                  <li>
                    <router-link class="nav-link" to="/parametros/tiposdespesa">
                      Tipos de despesa
                    </router-link>
                  </li>
                </ul>
              </li>
              <li class="menu-root dropdown">
                <a href="#" class="nav-link dropdown-toggle" @click="toggleDropdown">Configurações</a>
                <ul class="dropdown-menu">
                  <li>
                    <router-link class="nav-link" to="/configuracoes/usuarios">
                      Usuários
                    </router-link>
                  </li>
                  <li>
                    <router-link class="nav-link" to="/configuracoes/perfis">
                      Perfis
                    </router-link>
                  </li>
                  <li>
                    <router-link class="nav-link" to="/configuracoes/permissoes">
                      Permissões
                    </router-link>
                  </li>
                  <li>
                    <router-link class="nav-link" to="/configuracoes/logs">
                      Log de alterações
                    </router-link>
                  </li>
                  <li>
                    <router-link class="nav-link" to="/configuracoes/preferencias">
                      Preferências
                    </router-link>
                  </li>
                  <li>
                    <router-link class="nav-link" to="/configuracoes/faturas">
                      Faturas
                    </router-link>
                  </li>
                </ul>
              </li>
            </ul>
          </nav>
        </div>
      </div>
      <!-- end: header nav menu -->
    </div>

    <!-- start: search & user box -->
    <div class="header-right">

      <span class="separator"></span>

      <ul class="notifications">
        <li>
          <a href="#" class="dropdown-toggle notification-icon" data-toggle="dropdown" @click="atualizarAlertas">
            <i class="fas fa-bell"></i>
            <span class="badge" v-if="$root.user.Alertas.filter(a=>!a.Lido).length">{{$root.user.Alertas.filter(a=>!a.Lido).length}}</span>
          </a>

          <div class="dropdown-menu notification-menu">
            <div class="notification-title">
              <span class="float-right badge badge-default">
                {{$root.user.Alertas.filter(a=>!a.Lido).length}}
              </span>
              Alertas
            </div>

            <div class="content">
              <ul>

                <template v-for="item in $root.user.Alertas">
                  <li :key="item.Id">
                    <a :href="item.URL || '#'" class="clearfix">

                      <span class="title">{{item.Mensagem}}</span>

                      <span class="message">
                        {{item.DataCadastroDisplay}}
                        <span class="novo pull-right text-success" v-if="!item.Lido">novo!</span>
                      </span>
                    </a>
                  </li>
                  <hr :key="item.Mensagem"/>
                </template>
              </ul>



            </div>
          </div>
        </li>
      </ul>

      <span class="separator"></span>

      <div id="userbox" class="userbox">
        <a href="#" data-toggle="dropdown" style="display:block">
          <div class="profile-info">
            <span class="name">{{$root.user.Nome}}</span>
            <span class="role"><b>{{getEmpresaAtual()}}</b></span>
          </div>

          <i class="fa custom-caret pull-right"></i>
        </a>

        <div class="dropdown-menu">
          <ul class="list-unstyled">
            <li class="divider"></li>
            <li class="text-center" v-if="$root.user.TipoRequisicaoUsuarioLimite && $root.user.TipoRequisicaoUsuarioLimite.length">
              <span class="role"><b>Limites Aprovação</b></span>
            </li>
            <li :key="i.Id" v-for="i in $root.user.TipoRequisicaoUsuarioLimite.filter(f=>f.EmpresaId === $root.user.EmpresaId)">
              <span class="role">{{i.Tipo}}: <span class="pull-right">{{i.ValorDisplay}}</span></span>
            </li>

            <li class="divider" v-if="$root.user.TipoRequisicaoUsuarioLimite && $root.user.TipoRequisicaoUsuarioLimite.length"></li>
            <li :key="i.EmpresaId" v-for="i in $root.user.UsuarioEmpresa">
              <a role="menuitem" tabindex="-1" href="javascript:;" @click="trocarEmpresa(i.EmpresaId)"><i class="fas fa-building"></i> {{i.Empresa.Pessoa.ApelidoRazaoSocial}}</a>
            </li>
            <li class="divider"></li>
            <li>
              <router-link role="menuitem" tabindex="-1" to="/configuracoes/trocarsenha"><i class="fas fa-key"></i> Trocar senha</router-link>
            </li>
            <li>

              <a role="menuitem" tabindex="-1" @click="logout" href="javascript:;"><i class="fas fa-power-off"></i> Sair</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- end: search & user box -->
  </header>
</template>

<script>
import api from '@/services/api.js';
import { storeUser } from '@/services/user.service.js';

export default {
    name: 'navbar',
    data() {
        return {
            interval: null
        };
    },
    methods: {
      toggleDropdown($event) {
        if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent)
          || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0, 4))) {
          let $target = $($event.target);
          var $dropdown = $target.parent().find('.dropdown-menu').first();
          $dropdown.slideToggle();
        }          
        },
        trocarEmpresa: function (id) {
            let that = this;
            this.$root.user.EmpresaId !== id &&
            api.get('/configuracoes/trocarempresa?id=' + id)
                .then(response => {
                    if (response.data.success) {
                        let id = response.data.obj.id;
                        let responseUser = response.data.obj.user;

                        that.user = responseUser;
                        that.user.EmpresaId = id;
                        that.$root.user = responseUser;
                        storeUser(responseUser);
                        if (this.$router.currentRoute.path === '/') {
                            this.$router.go();
                        }
                        else {
                            this.$router.push('/');
                        }
                    }
                });
        },
        atualizarAlertas: function () {
            let that = this;
            let user = that.$root.user;

            let naoLidos = user.Alertas.filter(f => !f.Lido);

            if (naoLidos && naoLidos.length) {
                let ids = naoLidos.map(c => c.Id);
                let qs = ids.join('&ids=');
                qs = 'ids=' + qs;

                api.post('/configuracoes/VisualizarAlertas', ids)
                    .then(response => {
                        if (response.data.success) {
                            setTimeout(function () {
                                for (let i in naoLidos) {
                                    naoLidos[i].Lido = true;
                                }
                                storeUser(user);
                            }, 8000);
                            
                        }
                    });
            }
        },
        
        logout: function () {
            this.$router.push('/auth');
        },
        getEmpresaAtual: function () {
            let empresa = this.$root.user.UsuarioEmpresa.filter(f => f.EmpresaId === this.$root.user.EmpresaId)[0].Empresa;
            return empresa.Pessoa.ApelidoRazaoSocial;
        }
    },
    mounted: function () {
        if (typeof ($) !== 'undefined')
            $('#mainNav').find('[href$="' + location.hash.replace('#', '') + '"]').closest('.menu-root').addClass('active');

        let that = this;
        this.interval = setInterval(function () {
            that.$root.refreshUser();
        }, 300000);
    },
    beforeDestroy: function () {
        clearInterval(this.interval);
    }
    
};
</script>
