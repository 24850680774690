

import Vue from 'vue'; 
import VueRouter from 'vue-router'

import App from '@/App.vue';
import router from '@/services/router.js'


import Modal from '@/components/Modal.vue';
import Pagination from '@/components/Pagination.vue';
import DataFiltroItem from '@/components/DataFiltroItem.vue';
import DataFiltroCheckbox from '@/components/DataFiltroCheckbox.vue';
import vSelect from 'vue-select';
import VueTheMask from 'vue-the-mask';
import money from 'v-money';
import VueFormWizard from 'vue-form-wizard';

import 'vue-form-wizard/dist/vue-form-wizard.min.css';
import 'vue-select/dist/vue-select.css';
import '@/assets/custom.css';

import api from '@/services/api.js'
import { isAuthenticated, getUser, getLastRefresh, storeUser } from '@/services/user.service.js';
import { getEnvironmentUrl } from '@/services/environment.helper.js';

Vue.directive('focus', {
  inserted: function (el) {
    el.focus();
  }
});

Vue.config.devtools = location.origin.includes('localhost');
Vue.use(VueRouter);
Vue.use(VueTheMask);
Vue.use(money, {
  decimal: ',',
  thousands: '.',
  prefix: 'R$ ',
  suffix: ' #',
  precision: 2,
  masked: false
});
Vue.use(VueFormWizard)


Vue.component('v-select', vSelect);

Vue.component('modal', Modal);
Vue.component('pagination', Pagination);
Vue.component('checkbox-filter', DataFiltroCheckbox);
Vue.component('item-filter', DataFiltroItem);

export default new Vue({
  router,
  render: h => h(App),
  data() {
    return {
      count: '0',
      loading: false,
      user: getUser(),
      errors: [],
      authenticated: isAuthenticated(),
      hexTokens: {
        F: {
          pattern: /[0-9a-zA-Z]/,
          transform: v => v.toLocaleUpperCase()
        }
      },
      plateToken: {
        mask: 'AAA-#P##',
        tokens: {
          'P': {
            pattern: /[0-9a-zA-Z]/,
            transform: function (v) {
              return v.toLocaleUpperCase();
            }
          }
        }
      },
    };
  },
  methods: {
    export: function ($el, filtros) {
      let origin = getEnvironmentUrl();
      $($el).attr('disabled', 'disabled');
      let url = `${origin}${getParameterByName('tela', filtros)}?${filtros}`;
      api.get(url)
        .then(response => {
          let result = JSON.stringify(response.data);
          localStorage.setItem('ea-excel', result);
          const win = window.open('/templates/excel.html');
          win.addEventListener('onclose', function () {
            $($el).removeAttr('disabled');
          });
        });

     
    },
    print: function ($el, filtros) {
      let origin = getEnvironmentUrl();
      $($el).attr('disabled', 'disabled');
      let url = `${origin}${getParameterByName('tela', filtros)}?${filtros}`;
      api.get(url)
        .then(response => {
          let result = JSON.stringify(response.data);
          localStorage.setItem('ea-print', result);
          const win = window.open('/templates/print.html');
          win.addEventListener('onclose', function () {
            $($el).removeAttr('disabled');
          });
        });

     
    },
    exportCurrentTable: function () {
      fnExcelReport("report_table")
    },
    refreshUser: function () {
      let that = this;
      let lastRefresh = getLastRefresh();
      if (this.authenticated
        && (!lastRefresh || lastRefresh > 5)
      ) {
        api.get('/configuracoes/refresh')
          .then(response => {
            let { obj, success } = response.data;
            if (success) {
              that.user.Alertas = obj;
              storeUser(obj);
            }
          });
      }
    },
    validarVeiculo: function (veiculo) {

      this.errors = [];
      if (!veiculo) {
        this.errors.push('Veículo inválido.');
        return this.isValid();
      }
      if (!veiculo.TipoVeiculo) {
        this.errors.push('Campo "Tipo Veículo" é obrigatório')
      }
      if (!veiculo.Placa || veiculo.Placa.length < 7) {
        this.errors.push('Campo "Placa" inválido')
      }
      if (!veiculo.KM || veiculo.KM < 1) {
        this.errors.push('Campo "KM" inválido')
      }
      if (veiculo.Leilao &&
        (typeof (veiculo.TipoLeilao) === 'undefined')) {
        this.errors.push('Se o veículo for de leilão, por gentileza selecione o campo "Tipo Leilão"')
      }
      if (!veiculo.FIPEMarca || !veiculo.FIPEMarca.id) {
        this.errors.push('Selecione uma marca da FIPE')
      }
      if (!veiculo.FIPEModelo || !veiculo.FIPEModelo.id) {
        this.errors.push('Selecione um modelo da FIPE')
      }
      if (veiculo.Renvam && !(veiculo.Renvam.length < 9)) {
        this.errors.push('Campo "Renavam" inválido')
      }
      if (!veiculo.FIPEAno || !veiculo.FIPEAno.id) {
        this.errors.push('Selecione um ano modelo da FIPE')
      }
      if (!veiculo.AnoFab || veiculo.AnoFab < 1500) {
        this.errors.push('Campo "Ano Fabricação" inválido')
      }
      else if (veiculo.AnoFab > parseInt(veiculo.FIPEAno.name.substring(0, veiculo.FIPEAno.name.indexOf(' ')))) {
        this.errors.push('Campo "Ano Fabricação" deve ser menor ou igual ao ano do modelo da FIPE')
      }
      if (typeof (veiculo.CorId) === 'undefined') {
        this.errors.push('Campo "Cor" inválido')
      }
      if (typeof (veiculo.CarroceriaId) === 'undefined') {
        this.errors.push('Campo "Carroceria" inválido')
      }
      if (typeof (veiculo.CombustivelId) === 'undefined') {
        this.errors.push('Campo "Combustível" inválido')
      }
      if (typeof (veiculo.TransmissaoId) === 'undefined') {
        this.errors.push('Campo "Transmissao" inválido')
      }

      return this.isValid();
    },
    isValid: function () {
      if (this.errors.length) {
        displayMessage({
          type: 'error',
          msg: 'Erro na validação dos campos.'
        });
        return false;
      }
      return true;

    },

  },
  mounted: function () {
    let that = this;
    this.$nextTick(function () {
      that.refreshUser();
    });
  },

}).$mount('#app');
