import {  getUser, getToken } from '../services/user.service.js';
import VueRouter from 'vue-router'

const router = new VueRouter({
    mode: 'history',
    scrollBehavior(to, from, savedPosition) {
      //if (savedPosition) {
      //  return savedPosition
      //} else {
      //  return { x: 0, y: 0 }
      //}
      return { x: 0, y: 0 }

    },
    routes: [
        {
            path: '/',
            component: () => import('@/views/Veiculos.vue'),
            name: "Veiculos",
            meta: {
                title: 'Veículos',
                requiresAuth: true  
            },
      },
      {
        path: '/veiculos/create',
        component: () => import('@/views/VeiculoCreateOrUpdate.vue'),
        name: "Veículos Create",
        meta: {
          title: 'Criar novo veículo',
          requiresAuth: true
        },
      },
      {
        path: '/veiculos/edit/:id',
        component: () => import('@/views/VeiculoCreateOrUpdate.vue'),
        name: "Veículos Edit",
        meta: {
          title: 'Editar veículo',
          requiresAuth: true
        },
      },

        {
            path: '/avaliacoes',
            component: () => import('@/views/Avaliacao.vue'),
            name: "avaliacao",
            meta: {
                title: 'Avaliações',
                requiresAuth: true
            },
        },
        {
            path: '/avaliacoes/create',
            component: () => import('@/views/AvaliacaoCreate.vue'),
            name: "avaliacaocreate",
            meta: {
                title: 'Criar nova avaliação',
                requiresAuth: true
            },
      },
        {
            path: '/parametros/fornecedores',
          component: () => import('@/views/parametros/Fornecedores.vue'),
            name: "fornecedores",
            meta: {
                title: 'Fornecedores',
                requiresAuth: true
            },
        },
        {
            path: '/parametros/carrocerias',
            component: () => import('@/views/parametros/Carrocerias.vue'),
            name: "carrocerias",
            meta: {
                title: 'Carrocerias',
                requiresAuth: true
            },
        },
        {
            path: '/parametros/combustiveis',
          component: () => import('@/views/parametros/Combustiveis.vue'),
            name: "combustiveis",
            meta: {
                title: 'Combustíveis',
                requiresAuth: true
            },
        },
        {
            path: '/parametros/checklists',
          component: () => import('@/views/parametros/Checklists.vue'),
            name: "checklists",
            meta: {
                title: 'Checklists',
                requiresAuth: true
            },
        },
        {
            path: '/parametros/cores',
          component: () => import('@/views/parametros/Cores.vue'),
            name: "cores",
            meta: {
                title: 'Cores',
                requiresAuth: true
            },
        },
        {
            path: '/parametros/opcionais',
          component: () => import('@/views/parametros/Opcionais.vue'),
            name: "opcionais",
            meta: {
                title: 'Opcionais',
                requiresAuth: true
            },
        },
        {
            path: '/parametros/transmissoes',
          component: () => import('@/views/parametros/Transmissoes.vue'),
            name: "transmissoes",
            meta: {
                title: 'Transmissões',
                requiresAuth: true
            },
        },
        {
            path: '/parametros/servicos',
          component: () => import('@/views/parametros/Servicos.vue'),
            name: "servicos",
            meta: {
                title: 'Serviços',
                requiresAuth: true
            },
        },
        {
          path: '/parametros/departamentos',
          component: () => import('@/views/parametros/Departamentos.vue'),
          name: "departamentos",
          meta: {
            title: 'Departamentos',
            requiresAuth: true
          },
        },
        {
          path: '/parametros/tiposdespesa',
          component: () => import('@/views/parametros/TiposDespesa.vue'),
          name: "tiposDespesa",
          meta: {
            title: 'Tipos de despesas',
            requiresAuth: true
          },
        },

        {
            path: '/configuracoes/usuarios',
          component: () => import('@/views/configuracoes/Usuarios.vue'),
            name: "usuarios",
            meta: {
                title: 'Usuários',
                requiresAuth: true
            },
        },
        {
            path: '/configuracoes/perfis',
          component: () => import('@/views/configuracoes/Perfis.vue'),
            name: "perfis",
            meta: {
                title: 'Perfis',
                requiresAuth: true
            },
        },
        {
            path: '/configuracoes/permissoes',
          component: () => import('@/views/configuracoes/Permissoes.vue'),
            name: "permissoes",
            meta: {
                title: 'Permissões',
                requiresAuth: true
            },
        },
        {
            path: '/configuracoes/faturas',
          component: () => import('@/views/configuracoes/Faturas.vue'),
            name: "faturas",
            meta: {
                title: 'Minhas Faturas',
                requiresAuth: true
            },
        },
        {
            path: '/configuracoes/preferencias',
          component: () => import('@/views/configuracoes/Preferencias.vue'),
            name: "preferencias",
            meta: {
                title: 'Preferencias da empresa',
                requiresAuth: true
            },
        },
        {
            path: '/configuracoes/logs',
          component: () => import('@/views/configuracoes/Logs.vue'),
            name: "Logs de alterações",
            meta: {
                title: 'Logs de alterações',
                requiresAuth: true
            },
        },
        {
            path: '/configuracoes/trocarsenha',
          component: () => import('@/views/configuracoes/TrocarSenha.vue'),
            name: "Trocarsenha",
            meta: {
                title: 'Troca senha',
                requiresAuth: true
            },
        },
        {
            path: '/relatorios/requisicoes',
          component: () => import('@/views/relatorios/RelatorioRequisicoes.vue'),
            name: "RelatorioRequisicoes",
            meta: {
                title: 'Despesas por status',
                requiresAuth: true
            },
        },
        {
            path: '/relatorios/encerramentos',
          component: () => import('@/views/relatorios/Encerramentos.vue'),
            name: "RelatorioEncerramentos",
            meta: {
                title: 'Despesas por Encerramento',
                requiresAuth: true
            },
        },
        {
            path: '/relatorios/prontos',
          component: () => import('@/views/relatorios/Prontos.vue'),
            name: "RelatorioProntos",
            meta: {
                title: 'Veículos prontos',
                requiresAuth: true
            },
        },
        {
            path: '/relatorios/despesasestoque',
          component: () => import('@/views/relatorios/DespesasEmEstoque.vue'),
            name: "Despesas Em Estoque",
            meta: {
                title: 'Despesas em estoque',
                requiresAuth: true
            },
        },
        {
            path: '/relatorios/encerradosporperiodo',
          component: () => import('@/views/relatorios/EncerradosPorPeriodo.vue'),
            name: "EncerradosPorPeriodo",
            meta: {
                title: 'Encerrados por período',
                requiresAuth: true
            },
        },
        {
            path: '/relatorios/dashboarddespesas',
          component: () => import('@/views/relatorios/DashboardDespesas.vue'),
            name: "dashboarddespesas",
            meta: {
                title: 'Dashboard de despesas',
                requiresAuth: true
            },
        },  
        {
            path: '/relatorios/dashboardencerrados',
          component: () => import('@/views/relatorios/DashboardEncerrados.vue'),
            name: "dashboardencerrados",
            meta: {
                title: 'Dashboard de despesas encerradas',
                requiresAuth: true
            },
        },
        
        
        {
            path: '/auth',
          component: () => import('@/views/Login.vue'),
            name: "auth",
            meta: {
                title: 'Faça o login na plataforma'
            }
        },
        {
            path: '/forgot/:email?',
          component: () => import('@/views/Forgot.vue'),
            name: "forgot",
            meta: {
                title: 'Esqueci minha senha'
            }
        },
        {
            path: '/changepwd/:code/:email',
          component: () => import('@/views/ChangePwd.vue'),
            name: "changepwd",
            meta: {
                title: 'Trocar senha'
            }
        },
        {
          path: '/cadastro/dados/:id?',
          component: () => import('@/views/onboarding/Personal.vue'),
          name: "Onboarding Personal",
          meta: {
            title: 'Cadastro - Dados'
          }
        },
        {
          path: '/cadastro/endereco',
          component: () => import('@/views/onboarding/Address.vue'),
          name: "Onboarding Endereço",
          meta: {
            title: 'Cadastro - Endereço'
          }
        },
        {
          path: '/cadastro/contato',
          component: () => import('@/views/onboarding/Contact.vue'),
          name: "Onboarding Contato",
          meta: {
            title: 'Cadastro - Contato'
          }
        },
          {
            path: '/cadastro/regras',
            component: () => import('@/views/onboarding/Rules.vue'),
            name: "Onboarding Regras",
            meta: {
              title: 'Cadastro - Regras'
            }
      },
      {
        path: '/cadastro/conta',
        component: () => import('@/views/onboarding/Account.vue'),
        name: "Onboarding Dados de login",
        meta: {
          title: 'Cadastro - Conta'
        }
      },
  ],
});

router.beforeEach((to, from, next) => {
  $('.header-nav').removeClass('show');
  const user = getUser();
  if (to.matched.some(record => record.meta.requiresAuth)) {
      if (user == null) {
          next({
              path: '/auth',
              params: { nextUrl: to.fullPath }
          });
      } else {
          if (to.matched.some(record => record.meta.is_admin)) {
              if (user.IsAdmin == 1) {
                  next();
              }
              else {
                  next({ name: 'userboard' });
              }
          } else {
              next();
          }
      }
  } else if (to.matched.some(record => record.meta.guest)) {
      if (getToken() == null) {
          next();
      }
      else {
          next({ name: 'userboard' })
      }
  } else {
      next();
  }


});

router.afterEach((to, from) => {
  document.title = `Estoque Auto - ${to.meta.title || document.title}`;

  if (typeof ($) !== 'undefined') {
    $('#mainNav .menu-root.active').removeClass('active');
    $('#mainNav').find('[href$="' + to.path + '"]').closest('.menu-root').addClass('active');
  }

});

export default router;
