import { getToken, isAuthenticated } from '@/services/user.service.js';
import router from '@/services/router.js';
import root from '@/main.js';
import axios from 'axios';
import { getEnvironmentUrl } from '@/services/environment.helper.js';


const Api = axios.create({
  baseURL: getEnvironmentUrl(),
  //timeout: 1000,
  //headers: { 'X-Custom-Header': 'foobar' }
});

Api.defaults.headers.common['Content-Type'] = 'application/json';


Api.interceptors.request.use(function (config) {
  if (!root.loading && config.url !== '/configuracoes/refresh') {
    root.loading = true;
  }
  if (isAuthenticated()) {
    config.headers["Authorization"] = getToken();
  }
  return config;
});
Api.interceptors.response.use(function (response) {
  if (root.loading && response.config.url !== '/configuracoes/refresh') {
    root.loading = false;
  }
  if (response.data) {
    if (response.data.msg) {
      displayMessage({
        type: response.data.msgType,
        msg: `${response.data.msg}`
      });
    }
    if (response.data.errors) {
      root.errors = response.data.errors;
    }
  }
  return response;
}, function (error) {
  if (error.response.config.url !== '/configuracoes/refresh') {
    root.loading = false;
  }
  if (error && error.response && error.response.data) {
    console.log(error.response.data);

    if (error.response.data.msg) {
      displayMessage({
        type: error.response.data.msgType,
        msg: `${error.response.data.msg}`
      });
    }
    else {
      displayMessage({
        type: 'error',
        msg: `Erro no serviço = ${error.response.status}`
      });
    }
    if (error.response.data.errors) {
      root.errors = error.response.data.errors;
    }
  }
  else {
    displayMessage({
      type: 'error',
      msg: `Erro no serviço = ${(error && error.response) ? error.response.status : ''}`
    });
  }
  if (error && error.response && error.response.status === 401) {
    router.push('/auth');
  }
  console.log(error);
  return Promise.reject(error);
});
export default Api; 
