import axios from 'axios';
import { getEnvironmentUrl } from '@/services/environment.helper.js';

export function isAuthenticated() {
    return localStorage.getItem('user') ? true : false;
}
export function getToken() {
    // return authorization header with jwt token
    let user = JSON.parse(localStorage.getItem('user'));

    if (user && user.Token) {
        return 'Bearer ' + user.Token;
    } else {
        return null;
    }
}

export function getUser() {

    let stored = localStorage.getItem('user');
    return stored ? JSON.parse(stored) : null;

}
export function login(user) {
    //const requestOptions = {
    //    method: 'POST',
    //    headers: { 'Content-Type': 'application/json' },
    //    body: JSON.stringify({ username, password })
    //};

  return axios.post(getEnvironmentUrl() +'/auth', user, { headers: {"Content-Type": "application/json"} })
        .then(handleResponse)
        .then(user => {
            const { obj, success } = user;
            if (success) {
                storeUser(obj);
            }
            return user;            
        })
        .catch(err => {
            return { msg: err };
        });
}
export function emailRecovery(email) {
  return axios.post(getEnvironmentUrl() + '/auth/recovery', { Email: email}, { headers: { "Content-Type": "application/json" } });
}  
export function trocar(userData) {
  return axios.post(getEnvironmentUrl() + '/auth/ChangePassword', userData, { headers: { "Content-Type": "application/json" } });
}
export function storeUser(user) {
    // login successful if there's a jwt token in the response
    if (user.Token) {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('user', JSON.stringify(user));
        let date = new Date();
        let utcDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes()));
        let storeDate = JSON.stringify(utcDate);
        localStorage.setItem('lastRefresh', storeDate);

    }
}

export function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
    localStorage.removeItem('lastRefresh');
}
export function getLastRefresh() {
    let stored = localStorage.getItem('lastRefresh');
    if (stored) {
        stored = JSON.parse(stored);
        let storedDate = new Date(stored);
        let today = new Date();
        let diffMs = (today - storedDate);

        //var diffDays = Math.floor(diffMs / 86400000); // days
        //var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
        let diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes

        return diffMins;
    }
    return null;
}
function handleResponse(response) {

    const { data, status, statusText } = response;
    
    if (status === 401) {
        // auto logout if 401 response returned from api
        logout();
        location.reload(true);
    }
    else if (status !== 200 || !data.success) {
        const error = (data && data.msg) || statusText;
        return Promise.reject(error);
    }
    return data;
}
