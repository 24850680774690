<template>
  <div class="pagination-container row" v-if="pagination">
    <div class="col-lg-6" v-html="footerContent">
    </div>
    <div class="col-lg-6 text-right">
      <ul class="pagination">
        <li v-if="params.page != 1" class="paginate_button page-item">
          <a href="javascript:;" class="page-link" title="Ir para primeira página" @click="$emit('trocar-pagina',1)">
            <i class="fas fa-angle-left"></i>
          </a>
        </li>

        <li class="paginate_button page-item"
            v-if="params.page > pagination.MaxPages">
          <a href="javascript:;"
             class="page-link"
             :title="'Ir página ' + startPage(params.page,pagination)"
             @click="$emit('trocar-pagina',startPage(params.page,pagination))">
            <i class="fas fa-ellipsis-h"></i>
          </a>

        </li>
        <template v-for="(i) in definePages(params.page,pagination)">
          <li :key="i"
              :class="'paginate_button page-item ' + (i == params.page ? 'active disabled' : '')">
            <a class="page-link"
               href="javascript:;"
               :title="'Ir página ' + i"
               @click="$emit('trocar-pagina',i)">
              {{ i }}
            </a>
          </li>
        </template>
        <li class="paginate_button page-item"
            v-if="(startPage(params.page,pagination) + pagination.MaxPages) < (pagination.TotalPages)">
          <a href="javascript:;"
             class="page-link"
             :title="'Ir página ' + paginaAcima(params.page,pagination.MaxPages)"
             @click="$emit('trocar-pagina',paginaAcima(params.page,pagination.MaxPages))">
            <i class="fas fa-ellipsis-h"></i>
          </a>

        </li>

        <li v-if="(params.page != pagination.TotalPages) && (pagination.TotalPages > 1)"
            class="paginate_button page-item">
          <a href="javascript:;" class="page-link" title="Ir para última página" @click="$emit('trocar-pagina',pagination.TotalPages)">
            <i class="fas fa-angle-right"></i>
          </a>
        </li>

      </ul>
      <span class="m-datatable__pager-detail mr-2"> Exibindo {{pagination.TotalResults}} de {{pagination.Total}} registros</span>
    </div>
  </div>
</template>

<script>
export default {
    props: ['pagination', 'params', 'footerContent'],
    methods: {
      paginaAbaixo: function (page, maxPages) {
        while (maxPages % page !== 0) {
          page--;
        }
        return page - 1;
      },
      paginaAcima: function (page, maxPages) {
        while (page % maxPages !== 0) {
          page++;
        }
        return page + 1;
      },
      startPage: (page, pagination) => {
        let count = pagination.MaxPages;
        while (page > count) {
          count += pagination.MaxPages;
        }
        return count - pagination.MaxPages;
      },
      definePages: (page, pagination) => {
        let count = pagination.MaxPages;
        while (page > count) {
          count += pagination.MaxPages;
        }
        let startPage = count - pagination.MaxPages;
        let finalPage;
        if (pagination.TotalPages > pagination.MaxPages) {
          if (startPage + pagination.MaxPages > pagination.TotalPages) {
            finalPage = pagination.TotalPages;
          }
          else {
            finalPage = startPage + pagination.MaxPages;
          }
        }
        else {
          finalPage = pagination.TotalPages;
        }
        let arr = [];

        for (var i = (startPage + 1); i <= finalPage; i++) {
          arr.push(i);
        }
        return arr;
      }

    },

};
</script>
